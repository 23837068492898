import React from 'react';
// import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
// import "../../../../node_modules/bootstrap/dist/js/bootstrap.bundle"
// // import "../../../../public/index.html";
// // import "../node_modules/bootstrap/dist/js/bootstrap.bundle";

import Home from './Home';
import About from './About';
import Capabilities from './Capabilities';
import Products from './Products';
import Engineering from './Engineering';
import Manufacturing from './Manufacturing';
import QualityControl from './QualityControl';
import Contact from './Contact';
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../../../node_modules/bootstrap/dist/js/bootstrap.bundle";
// import Navbar from './Navbar';
// import Footer from './Footer';
import {Switch, Route, Redirect} from 'react-router-dom';

const App = () => {

  return (
    
    <>
    {/* <Navbar /> */}
    
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/about" component={About} />
        <Route exact path="/capabilities" component={Capabilities} />
        <Route exact path="/engineering" component={Engineering} />
        <Route exact path="/manufacturing" component={Manufacturing} />
        <Route exact path="/qualitycontrol" component={QualityControl} />
        <Route exact path="/products" component={Products} />
        <Route exact path="/contact" component={Contact} />
        <Redirect to="/" />
      </Switch>
    {/* <Footer /> */}
</>
 
  )
}

export default App;